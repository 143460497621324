import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { CreateApplicationDraftParams } from "#components/provider/ApplicationProvider/ApplicationTypes";
import { restPaths } from "#constants/restPaths";

import { HTTPcreateApplicationRes, LOSAPIResponse } from "./client.types";

/**
 * creates a draft loan application for users that are not authenticated
 * @param vin refinance vehicle vin (string)
 * @param state refinance vehicle state (string)
 * @param plate refinance vehicle license plate (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */

const createDraft: (
  vehicleInfo: CreateApplicationDraftParams,
) => LOSAPIResponse<HTTPcreateApplicationRes> = async (vehicleInfo) => {
  const query = vehicleInfo.vin
    ? `?vin=${vehicleInfo.vin}${
        vehicleInfo.year ? `&year=${vehicleInfo.year}` : ""
      }${vehicleInfo.make ? `&make=${vehicleInfo.make}` : ""}${
        vehicleInfo.model ? `&model=${vehicleInfo.model}` : ""
      }${vehicleInfo.trim ? `&trim=${vehicleInfo.trim}` : ""}${
        vehicleInfo.mileage ? `&mileage=${vehicleInfo.mileage}` : ""
      }${
        vehicleInfo.externalVehicleProfileId
          ? `&externalVehicleProfileId=${vehicleInfo.externalVehicleProfileId}`
          : ""
      }${
        vehicleInfo.externalVehicleProfileProvider
          ? `&externalVehicleProfileProvider=${vehicleInfo.externalVehicleProfileProvider}`
          : ""
      }`
    : `?state=${vehicleInfo.state}&plate=${vehicleInfo.plate}${
        vehicleInfo.mileage ? `&mileage=${vehicleInfo.mileage}` : ""
      }`;
  const response = await makeRequest<HTTPcreateApplicationRes>(
    HTTPMethod.POST,
    `${restPaths.application().draft}${query}`,
    {},
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default createDraft;
