var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"blFgn3CcvDBcOkb_gX8BY"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const env = (() => {
  if (typeof window !== "undefined") {
    if (window.location.host.indexOf("tenet.com") !== -1) {
      return "production";
    }
    if (window.location.host.indexOf("qa.nonprodtenet.com") !== -1) {
      return "qa";
    }
    if (
      window.location.host.indexOf("dev.nonprodtenet.com") !== -1 ||
      window.location.host.indexOf("amplifyapp.com") !== -1
    ) {
      return "development";
    }
    if (window.location.host.indexOf("local") !== -1) {
      return "local";
    }
  }
  return process.env.NODE_ENV || "production";
})();

// if (env !== "local") {
Sentry.init({
  environment: env,
  dsn:
    SENTRY_DSN ||
    "https://dca2a7b30f86463f8408dab97118d086@o4504402165104640.ingest.sentry.io/4504402169036801",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  beforeSend(event, hint) {
    const processedEvent = { ...event };
    if (hint && hint.originalException) {
      const cause = hint.originalException.cause || null;

      if (cause && cause.message) {
        return {
          ...event,
          loggedMessage: processedEvent.message,
          message: cause.message,
        };
      }
    }

    return processedEvent;
  },
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ["error"],
    }),
  ],
});
// }
