import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";

export enum BETaskRequirement {
  BUSINESS_OWNERSHIP = "BUSINESS_OWNERSHIP", // Doc required
  EARNINGS_STATEMENT = "EARNINGS_STATEMENT", // Doc required
  IDENTITY_VERIFICATION = "IDENTITY_VERIFICATION", // Doc required
  INCOME_VERIFICATION = "INCOME_VERIFICATION", // Doc required
  INSURANCE_VERIFICATION = "INSURANCE_VERIFICATION", // Doc required
  PAYOFF_VERIFICATION = "PAYOFF_VERIFICATION", // Doc required
  PROOF_OF_DOWN_PAYMENT = "PROOF_OF_DOWN_PAYMENT", // Doc required
  PURCHASE_ORDER_VERIFICATION = "PURCHASE_ORDER_VERIFICATION", // Doc required
  VEHICLE_HISTORY_REPORT = "VEHICLE_HISTORY_REPORT", // Doc required
}

export enum DocumentPurpose {
  AGENT_UPLOADED = "AGENT_UPLOADED",
  BUSINESS_OWNERSHIP = "BUSINESS_OWNERSHIP",
  DEALER_DOCUMENTS = "DEALER_DOCUMENTS",
  DEALER_PAYOFF = "DEALER_PAYOFF",
  DOCUSIGN = "DOCUSIGN",
  EARNINGS_STATEMENT = "EARNINGS_STATEMENT",
  HARD_CREDIT_CHECK = "HARD_CREDIT_CHECK",
  INCOME = "INCOME",
  INSURANCE = "INSURANCE",
  LICENSE_BACK = "LICENSE_BACK",
  LICENSE_FRONT = "LICENSE_FRONT",
  MVPA = "MVPA",
  PROOF_OF_DOWN_PAYMENT = "PROOF_OF_DOWN_PAYMENT",
  SOFT_CREDIT_CHECK = "SOFT_CREDIT_CHECK",
  TILA = "TILA",
  VEHICLE_HISTORY_REPORT = "VEHICLE_HISTORY_REPORT",
}

export enum BETaskStatus {
  /** Missing required app data */
  NOT_STARTED = "NOT_STARTED",
  /** Task is available, user action required */
  STARTED = "STARTED",
  /** User sent document/data */
  PENDING = "PENDING",
  /** Agent action required in Digifi */
  IN_REVIEW = "IN_REVIEW",
  /** Task is approved */
  COMPLETE = "COMPLETE",
  /** Kicked back by agent */
  REJECTED = "REJECTED",
}

export enum BETaskAssignee {
  TENET = "TENET",
  APPLICANT = "APPLICANT",
}

export type BETask = {
  id: string;
  appRefId: string;
  applicantRefId?: string;
  requirement: BETaskRequirement;
  status: BETaskStatus;
  /** this is who is responsible for a task */
  assignee: BETaskAssignee;
};

export type HTTPGetBETasksRes = {
  data: BETask[];
};

/**
 * Gets tasks for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getTasks: (applicationId: string) => LOSAPIResponse<HTTPGetBETasksRes> =
  async (applicationId) => {
    const response = await makeRequest<HTTPGetBETasksRes>(
      HTTPMethod.GET,
      restPaths.application(applicationId).tasks.get,
      {},
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }

    return { response: null, error: response };
  };

export default getTasks;
