/** ******************************************************************************
Compare VINs
 * Compares the VIN on file for the application with the inputted VIN
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse, Vehicle, VProfileDto } from "./client.types";

export type VinValidationInfo = {
  vehicle: Vehicle;
  vehicleProfilesForVin: VProfileDto[];
  vinMatchesVehicle: boolean;
};

type HTTPCompareVinsResponse = {
  data: VinValidationInfo;
};

const validateVinMatchesVehicle: (
  applicationId: string,
  payload: { vin: string },
) => LOSAPIResponse<HTTPCompareVinsResponse | null> = async (
  applicationId,
  { vin },
) => {
  const response = await makeRequest<HTTPCompareVinsResponse | null>(
    HTTPMethod.POST,
    restPaths.vehicle(applicationId).compareVins,
    {
      body: {
        vin,
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default validateVinMatchesVehicle;
