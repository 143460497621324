/** ******************************************************************************
Create Vehicle
 * uses the /appId/vehicle/post endpoint to create an applications associated vehicle
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  HTTPCreateVehicleRes,
  LOSAPIResponse,
  VehiclePrivatePurchaseVendor,
  VehicleProfileProvider,
} from "./client.types";

/**
 * Updates/sets an application's associated vehicle
 * @param applicationId loan application ID for the current application (string)
 * @param vehicle vehicle properties that are to be updated. (updating the vin deletes the current vehicle and creates a new one)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const createVehicle: (
  applicationId: string,
  vehicle: {
    privatePurchaseVendor?: VehiclePrivatePurchaseVendor;
    vin?: string;
    externalVehicleProfileId: string;
    externalVehicleProfileProvider: VehicleProfileProvider;
    mileage?: number;
    usedStatus?: string;
    salePriceCents?: number;
    purchaseSource?: string;
    deliveryDate?: Date;
  },
) => LOSAPIResponse<HTTPCreateVehicleRes> = async (
  applicationId,
  {
    privatePurchaseVendor,
    vin,
    externalVehicleProfileId,
    externalVehicleProfileProvider,
    mileage,
    usedStatus,
    salePriceCents,
    purchaseSource,
    deliveryDate,
  },
) => {
  const response = await makeRequest<HTTPCreateVehicleRes>(
    HTTPMethod.POST,
    restPaths.application(applicationId).vehicle.post,
    {
      body: {
        privatePurchaseVendor,
        vin,
        externalVehicleProfileId,
        externalVehicleProfileProvider,
        mileage,
        usedStatus,
        salePriceCents,
        purchaseSource,
        deliveryDate,
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default createVehicle;
