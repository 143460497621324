import dynamic from "next/dynamic";

import { GexaModal } from "#components/partial/GexaModal/GexaModal";
import { NextSteps } from "#components/partial/NextSteps/NextSteps";
import { PaymentPreferencesForm } from "#components/partial/PaymentPreferencesForm/PaymentPreferencesForm";
import { ReferralModal } from "#components/partial/Referrals/ReferralAnnounce";

const SelectChargerForm = dynamic(
  () => import("#components/partial/SelectChargerForm/SelectChargerForm"),
);
const ChargerInstallationModalWithProviders = dynamic(
  () =>
    import(
      "#components/partial/ChargerInstallationModal/ChargerInstallationModal"
    ),
);
const FinalLoanSummary = dynamic(
  () => import("#components/partial/FinalLoanSummary/FinalLoanSummary"),
);
const DealershipModal = dynamic(
  () => import("#components/partial/DealershipForm/DealershipModal"),
);
const BusinessOwnershipUpload = dynamic(
  () => import("#components/partial/DocUpload/BusinessOwnershipUpload"),
);
const EarningStatementsUpload = dynamic(
  () => import("#components/partial/DocUpload/EarningStatementsUpload"),
);
const IncomeUpload = dynamic(
  () => import("#components/partial/DocUpload/IncomeUpload"),
);
const LicenseUpload = dynamic(
  () => import("#components/partial/DocUpload/LicenseUpload"),
);
const PayoffUpload = dynamic(
  () => import("#components/partial/DocUpload/PayoffUpload"),
);
const ProofOfDownPayment = dynamic(
  () => import("#components/partial/DocUpload/ProofOfDownPaymentUpload"),
);
const PurchaseFormFlow = dynamic(
  () =>
    import("#components/partial/DocUpload/PurchaseFormFlow/PurchaseFormFlow"),
);
const VehicleHistoryReport = dynamic(
  () => import("#components/partial/DocUpload/VehicleHistoryReportUpload"),
);
const EditFinanceFormWithProviders = dynamic(
  () => import("#components/partial/EditFinanceModal/EditFinanceModal"),
);
const EVPPAddOnWithProviders = dynamic(
  () => import("#components/partial/EVPPModal/EVPPModal"),
);
const GapAddOnWithProviders = dynamic(
  () => import("#components/partial/GapAddOn/GapAddOn"),
);
const HardCreditCheck = dynamic(
  () => import("#components/partial/HardCreditCheck/HardCreditCheck"),
);
const OptionalExtrasModal = dynamic(
  () => import("#components/partial/OptionalExtrasModal/OptionalExtrasModal"),
);
const PaymentHistoryModal = dynamic(
  () => import("#components/partial/PaymentHistoryModal/PaymentHistoryModal"),
);
const PickupDateForm = dynamic(
  () => import("#components/partial/PickupDateForm/PickupDateForm"),
);
const SsnForm = dynamic(() => import("#components/partial/SsnForm/SsnForm"));

const AddressHistoryForm = dynamic(
  () => import("#components/partial/AddressHistoryForm/AddressHistoryForm"),
);
const InsuranceUpload = dynamic(
  () => import("#components/partial/DocUpload/InsuranceUpload"),
);
const SavingsCard = dynamic(
  () => import("#components/pages/Dashboard/VehicleInsights/SavingsCard"),
);
const SmartChargingSessionHistory = dynamic(
  () =>
    import(
      "#components/pages/Dashboard/VehicleInsights/SessionHistory/SessionHistory"
    ),
);
const SmartChargingFlow = dynamic(
  () =>
    import(
      "#components/pages/Dashboard/VehicleInsights/SmartCharging/SmartChargingFlow"
    ),
);
const SmartChargingSettings = dynamic(
  () =>
    import(
      "#components/pages/Dashboard/VehicleInsights/SmartChargingSettings/SmartChargingSettings"
    ),
);
const UtilityInfo = dynamic(
  () =>
    import(
      "#components/pages/Dashboard/VehicleInsights/UtilityInfo/UtilityInfo"
    ),
);
const VerifyChargerLocationModal = dynamic(
  () =>
    import(
      "#components/pages/Dashboard/VehicleInsights/VerifyLocationModal/VerifyLocationModal"
    ),
);
const AutopayEnrollModal = dynamic(
  () => import("#components/partial/AutopayEnrollModal/AutopayEnrollModal"),
);
const InsightsUnlinkModal = dynamic(
  () => import("#components/partial/InsightsUnlinkModal/InsightsUnlinkModal"),
);
const LoanDetailsModal = dynamic(
  () => import("#components/partial/LoanDetailsModal/LoanDetailsModal"),
);
const OneTimePaymentModal = dynamic(
  () => import("#components/partial/OneTimePaymentModal/OneTimePaymentModal"),
);
const HomeLoanCalculatorCardModal = dynamic(
  () =>
    import(
      "#components/partial/HomeLoanCalculator/HomeLoanCalculatorCardModal"
    ),
);
const AdjustVehicleModal = dynamic(
  () => import("#components/partial/AdjustVehicleModal/index"),
);

const PromoWarningModal = dynamic(
  () => import("#components/partial/PromoWarning/PromoWarning"),
);

// 1. Add modals here
export enum ModalType {
  SELECT_CHARGER = "SELECT_CHARGER",
  ADJUST_VEHICLE = "ADJUST_VEHICLE",
  INCOME_UPLOAD = "INCOME_UPLOAD",
  INSURANCE_UPLOAD = "INSURANCE_UPLOAD",
  LICENSE_UPLOAD = "LICENSE_UPLOAD",
  PROOF_OF_DOWN_PAYMENT = "PROOF_OF_DOWN_PAYMENT",
  PURCHASE_FORM = "PURCHASE_FORM",
  VEHICLE_HISTORY_REPORT = "VEHICLE_HISTORY_REPORT",
  SSN_FORM = "SSN_FORM",
  ADDRESS_FORM = "ADDRESS_FORM",
  PAYOFF_UPLOAD = "PAYOFF_UPLOAD",
  DEALERSHIP_FORM = "DEALERSHIP_FORM",
  BUSINESS_OWNERSHIP = "BUSINESS_OWNERSHIP",
  BUSINESS_EARNINGS = "BUSINESS_EARNINGS",
  PAYMENT_PREFERENCES = "PAYMENT_PREFERENCES",
  GAP_ADDONS = "GAP_ADDONS",
  EVPP_ADDONS = "EVPP_ADDONS",
  REFERRAL_ANNOUNCE = "REFERRAL_ANNOUNCE",
  HARD_CREDIT_CHECK = "HARD_CREDIT_CHECK",
  PICKUP_DATE = "PICKUP_DATE",
  AMOUNT_FINANCED = "AMOUNT_FINANCED",
  CHARGER_INSTALL = "CHARGER_INSTALL",
  PAYMENT_HISTORY = "PAYMENT_HISTORY",
  VERIFY_CHARGING_LOCATION = "VERIFY_CHARGING_LOCATION",
  SMART_CHARGING_ENROLLMENT = "SMART_CHARGING_ENROLLMENT",
  ONE_TIME_PAYMENT_MODAL = "ONE_TIME_PAYMENT_MODAL",
  AUTOPAY_ENROLL_MODAL = "AUTOPAY_ENROLL_MODAL",
  LOAN_DETAILS_MODAL = "LOAN_DETAILS_MODAL",
  SMART_CHARGING_SESSION_HISTORY = "SMART_CHARGING_SESSION_HISTORY",
  OPTIONAL_EXTRAS = "OPTIONAL_EXTRAS",
  UTILITY_INFO = "UTILITY_INFO",
  SMART_CHARGING_SAVINGS_CARD = "SMART_CHARGING_SAVINGS_CARD",
  SMART_CHARGING_SETTINGS = "SMART_CHARGING_SETTINGS",
  UNLINK_SMART_CHARGING = "UNLINK_SMART_CHARGING",
  FINALIZE_MODAL = "FINALIZE_MODAL",
  NEXT_STEPS = "NEXT_STEPS",
  HOME_LOAN_CALCULATOR_CARD = "HOME_LOAN_CALCULATOR_CARD",
  PROMO_WARNING_MODAL = "PROMO_WARNING_MODAL",
  GEXA = "GEXA",
}

export const NoopElement = () => null;

// 2. Map type to component
// Always use an element or component here,
// or you'll break the downstream dependencies
export const ModalMap = {
  [ModalType.ADJUST_VEHICLE]: AdjustVehicleModal,
  [ModalType.INCOME_UPLOAD]: IncomeUpload, // gross
  [ModalType.INSURANCE_UPLOAD]: InsuranceUpload, // gross
  [ModalType.LICENSE_UPLOAD]: LicenseUpload, // Fully refactored
  [ModalType.PURCHASE_FORM]: PurchaseFormFlow, // Acceptable
  [ModalType.PROOF_OF_DOWN_PAYMENT]: ProofOfDownPayment,
  [ModalType.VEHICLE_HISTORY_REPORT]: VehicleHistoryReport,
  [ModalType.SSN_FORM]: SsnForm, // seems bout right
  [ModalType.ADDRESS_FORM]: AddressHistoryForm, // kinda gross
  [ModalType.PAYOFF_UPLOAD]: PayoffUpload, // gross
  [ModalType.DEALERSHIP_FORM]: DealershipModal,
  [ModalType.BUSINESS_OWNERSHIP]: BusinessOwnershipUpload,
  [ModalType.BUSINESS_EARNINGS]: EarningStatementsUpload,
  [ModalType.PAYMENT_PREFERENCES]: PaymentPreferencesForm,
  [ModalType.GAP_ADDONS]: GapAddOnWithProviders, // scuffed
  [ModalType.EVPP_ADDONS]: EVPPAddOnWithProviders, // super scuffed
  [ModalType.REFERRAL_ANNOUNCE]: ReferralModal,
  [ModalType.HARD_CREDIT_CHECK]: HardCreditCheck,
  [ModalType.PICKUP_DATE]: PickupDateForm,
  [ModalType.AMOUNT_FINANCED]: EditFinanceFormWithProviders,
  [ModalType.CHARGER_INSTALL]: ChargerInstallationModalWithProviders,
  [ModalType.PAYMENT_HISTORY]: PaymentHistoryModal,
  [ModalType.SMART_CHARGING_ENROLLMENT]: SmartChargingFlow,
  [ModalType.VERIFY_CHARGING_LOCATION]: VerifyChargerLocationModal,
  [ModalType.ONE_TIME_PAYMENT_MODAL]: OneTimePaymentModal,
  [ModalType.AUTOPAY_ENROLL_MODAL]: AutopayEnrollModal,
  [ModalType.LOAN_DETAILS_MODAL]: LoanDetailsModal,
  [ModalType.SMART_CHARGING_SESSION_HISTORY]: SmartChargingSessionHistory,
  [ModalType.OPTIONAL_EXTRAS]: OptionalExtrasModal,
  [ModalType.UTILITY_INFO]: UtilityInfo,
  [ModalType.SMART_CHARGING_SAVINGS_CARD]: SavingsCard,
  [ModalType.SMART_CHARGING_SETTINGS]: SmartChargingSettings,
  [ModalType.UNLINK_SMART_CHARGING]: InsightsUnlinkModal,
  [ModalType.FINALIZE_MODAL]: FinalLoanSummary,
  [ModalType.NEXT_STEPS]: NextSteps,
  [ModalType.HOME_LOAN_CALCULATOR_CARD]: HomeLoanCalculatorCardModal,
  [ModalType.SELECT_CHARGER]: SelectChargerForm,
  [ModalType.PROMO_WARNING_MODAL]: PromoWarningModal,
  [ModalType.GEXA]: GexaModal,
} as const;
