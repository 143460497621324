/** ******************************************************************************
Initialize Page
 * Gets the initial stage of the user and application for navigation purposes
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { getAuth } from "#auth/utils";
import { restPaths } from "#constants/restPaths";

import { HTTPInitPageResponse } from "./client.types";

const initializePage: () => Promise<{
  response: null | HTTPResponse<HTTPInitPageResponse>;
  error: null | HTTPResponse<HTTPInitPageResponse>;
}> = async () => {
  const auth = await getAuth();
  const token = await auth.currentUser?.getIdToken();
  const isAuthed = !!token;
  const response = await makeRequest<HTTPInitPageResponse>(
    HTTPMethod.GET,
    restPaths.init,
    {},
    !!isAuthed,
  );
  if (!response.error) {
    /* MPR, 2023/12/11: Is this a great place for a side effect? no. However, we really do want to
       reset intercom on each call of /init, to guarentee they are correctly logged in or out */
    if (typeof window !== "undefined" && response.body != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      window.intercomSettings = window.intercomSettings || {};
      if (response?.body?.identity != null && response?.body?.userId != null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        window.intercomSettings.user_hash = response.body.identity;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        window.intercomSettings.user_id = response.body.userId;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.Intercom) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          window.Intercom("shutdown");
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          window.Intercom("boot", window.intercomSettings);
        }
      }
    }
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default initializePage;
